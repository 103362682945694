import React from 'react'
import {graphql, Link} from 'gatsby'
import Img from "gatsby-image";
import {uniqBy} from 'lodash';
import {Helmet} from "react-helmet";
import Layout from "../components/Layout";
import classNames from 'classnames';

class TagRoute extends React.Component {
  render() {
    const posts = uniqBy(this.props.data.allMarkdownRemark.edges, 'node.fields.slug')
    const tag = this.props.pageContext.tag
    const totalCount = posts.length
    const pageInIframe = typeof window !== `undefined` ? (window.location !== window.parent.location) : true;

    return (
      <Layout noNav={pageInIframe}>
        <Helmet>
          <base target="_parent"/>
        </Helmet>
        <div className={classNames({
          'tag-layout': true,
          'page-in-iframe': pageInIframe
        })}>
          <div className="tag-header">
            {totalCount} release{totalCount === 1 ? '' : 's'} in <span className="highlighted-tag">{tag}</span>:
          </div>
          <div className="plain-grid">
            {posts.map(post => (
              <a href={post.node.fields.slug} key={post.node.fields.slug}>
                <div
                  className="plain-grid-img no-title"
                  title={`${post.node.frontmatter.artist} - ${post.node.frontmatter.title}`}
                >
                  <Img
                    fluid={post.node.frontmatter.coverimage.childImageSharp.fluid}
                    alt={`Cover of "${post.node.frontmatter.title}"`}
                  />
                </div>
              </a>)
            )}
          </div>
        </div>
        {pageInIframe && <div className="nav-dummy"/>}
      </Layout>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            artist
            coverimage {
              childImageSharp {
                fluid(maxWidth: 300, quality: 70) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
